*{
    box-sizing: border-box;
}
html,body{
    margin:0;
    padding:0;
    
}
body{
font-family:  var(--defaultFontFamilly);
}
:root{
    --defaultFontFamilly: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
    --colorPrimary:  rgb(63, 176, 187);
}

    .headerApp {
        background-color: var(--colorPrimary);
        padding: 20px;
        display: flex;
        align-items: center;
        box-shadow: 0 0 10px rgba(0,0,0,.3);

        h1 {
            flex: 1;
            margin: 0;
            padding: 0;
            font-size: 24px;

            small {
                font-size: .7em;
            }

            a {
                color: white;
                text-decoration: none;
            }
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 15px;
        }
    }

    .footerApp {
        background-color: var(--colorPrimary);
        padding: 20px;
        text-align: center;
        color: white;
        font-size: .9em;
        line-height: 1.5em;
        margin-top: 50px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .3);

        .footerContent {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 15px;
            }
        }

        a {
            display: block;
            padding-top:30px;
            color: white;
            font-size: .7rem;
        }


    }
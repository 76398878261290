.siteContent {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;

    p,
    ul {
        line-height: 2em;
        ;
    }

    ul {
        list-style-type: none;

        li {
            span {
                &::before {
                    content: '• ';
                    color: var(--colorPrimary);
                }
            }
        }
    }

    img {
        max-width: 100%;
        border-radius: 10px;
        margin: 20px 0;

    }

    h2 {
        color: var(--colorPrimary);
        margin-top: 0;

        &:after {
            content: '';
            display: block;
            width: 100px;
            height: 2px;
            background-color: var(--colorPrimary);
            margin: 20px auto;
        }
    }
}